import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const cryptoExchangesHeaders = {
        'x-rapidapi-host': 'coingecko.p.rapidapi.com',
    'x-rapidapi-key': '0444a31721msh4cb20a5be417bafp166782jsn12eb1e9c9aa9'
}

const baseUrl ='https://coingecko.p.rapidapi.com';

const createRequest = (url) => ({
    url,headers:cryptoExchangesHeaders
})

export const cryptoExchangesApi = createApi({
    reducerPath:'cryptoExchangesApi',
    baseQuery:fetchBaseQuery({baseUrl}),
    endpoints:(builder) =>({
        getCryptoExchanges:builder.query({
            query:() => createRequest(`/exchanges`)
        })
    })
})

export const {useGetCryptoExchangesQuery} = cryptoExchangesApi



