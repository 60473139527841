import React from 'react'
import millify from 'millify';
import { Typography, Row, Col, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import { useGetTotalInfoQuery } from '../services/cryptoApi';
import {Cryptocurrencies} from "./index";
import News from "./News";
import Loader from "./Loader";

const { Title } = Typography;

const Homepage = () => {

    const {data,isFetching} = useGetTotalInfoQuery('');
    console.log(data)
    let globalStats

    if(isFetching){
        return <Loader/>
    }
    else{
        globalStats  = data
        console.log(globalStats)
    }

    return (
        <>
            <Title level={2} className='heading' style={{ textAlign: 'center'}}>
                Global Crypto Stats
            </Title>
            <Row style={{ textAlign: 'center'}}>
                <Col span={12}><Statistic title="Total Tokens" value={globalStats.totalTokens}/></Col>
                <Col span={12}><Statistic title="Total Market Cap" value={globalStats.marketCap}/></Col>
                <Col span={12}><Statistic title="Total Volume" value={globalStats.volume}/></Col>
                <Col span={12}><Statistic title="BTC Dominance" value={globalStats.dominance}/></Col>
            </Row>
            <div className="home-heading-container">
                <Title level={2} className="home-title">Top 10 cryptocurrencies in the world</Title>
                <Title level={3} className="show-more"><Link to="cryptocurrencies"><p>Show More</p> </Link></Title>
            </div>
            <Cryptocurrencies simplified={true}/>
            <div className="home-heading-container">
                <Title level={2} className="home-title">Latest Crypto News</Title>
                <Title level={3} className="show-more"><Link to="cryptocurrencies">Show More</Link></Title>
            </div>
            <News simplified/>
        </>
    )
}

export default Homepage
