import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const cryptoNewsHeaders = {
    'x-rapidapi-host': 'mboum-finance.p.rapidapi.com',
    'x-rapidapi-key': '0444a31721msh4cb20a5be417bafp166782jsn12eb1e9c9aa9'
}

const baseUrl ='https://mboum-finance.p.rapidapi.com';

const createRequest = (url) => ({
    url,headers:cryptoNewsHeaders
})

export const cryptoNewsApi = createApi({
    reducerPath:'cryptoNewsApi',
    baseQuery:fetchBaseQuery({baseUrl}),
    endpoints:(builder) =>({
        getCryptoNews:builder.query({
            query:(newsCategory,count) => createRequest(`/ne/news`)
        })
    })
})

export const {useGetCryptoNewsQuery} = cryptoNewsApi



