import React, { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { useParams } from 'react-router-dom';
import millify from 'millify';
import { Col, Row, Typography, Select } from 'antd';
import { MoneyCollectOutlined, DollarCircleOutlined, FundOutlined, ExclamationCircleOutlined, StopOutlined, TrophyOutlined, CheckOutlined, NumberOutlined, ThunderboltOutlined } from '@ant-design/icons';
import {useGetCryptoDetailsQuery, useGetCryptosQuery, useGetFearAndGreedIdxQuery} from '../services/cryptoApi';
import LineChart from "./LineChart";
import {Cryptocurrencies} from "./index";
import Homepage from "./Homepage";
import Loader from "./Loader";




const { Title, Text } = Typography;
const { Option } = Select;

const CryptoDetails = () => {

    let {coinId} = useParams()
    const [timeperiod,setTimeperiod] = useState('7')
    const {data,isFetching} = useGetCryptoDetailsQuery(coinId);
    const {data:data2} = useGetFearAndGreedIdxQuery(timeperiod)


    if(data2 == undefined) return <Loader/>;
    if(data == undefined) return <Loader/>;
    if(isFetching) return <Loader/>;
    if(Object.keys(data).length == 0){
        alert('BAD API!!!!! PlEASE CHOOSE ANOTHER COIN :(')
        return <Homepage/>
    }

    const cryptoDetails = data;
    const time = ['7','14','30','60','360'];

    const stats = [
        { title: 'Price to USD', value: `$ ${millify(cryptoDetails.quotes.map((item) =>item.price))}`, icon: <DollarCircleOutlined /> },
        { title: 'Rank', value: cryptoDetails.cmcRank, icon: <NumberOutlined /> },
        { title: '24h Volume', value: `$ ${millify(cryptoDetails.quotes.map((item) =>item.volume24h))}`, icon: <ThunderboltOutlined /> },
        { title: 'Market Cap', value: `$ ${cryptoDetails.ath && millify(cryptoDetails.quotes.map((item) =>item.marketCap))}`, icon: <DollarCircleOutlined /> },
        { title: 'All-time-high(daily avg.)', value: `$ ${millify(cryptoDetails.ath)}`, icon: <TrophyOutlined /> },
    ];

    const genericStats = [
        { title: 'All Time Low', value: `$ ${millify(cryptoDetails.atl)}`, icon: <FundOutlined /> },
        { title: 'All Time High', value:`$ ${millify(cryptoDetails.ath)}` , icon: <MoneyCollectOutlined /> },
        { title: 'Market Pair Count', value:`$ ${millify(cryptoDetails.marketPairCount)}` , icon: <ExclamationCircleOutlined /> },
        { title: 'Total Supply', value: `$ ${millify(cryptoDetails.totalSupply)}`, icon: <ExclamationCircleOutlined /> },
        { title: 'Circulating Supply', value: `$ ${millify(cryptoDetails.circulatingSupply)}`, icon: <ExclamationCircleOutlined /> },
    ];

    return (
        <Col className="coin-detail-container">
            <Col className="coin-heading-container">
                <Title level={2} className="coin-name">
                    {cryptoDetails.name}({cryptoDetails.symbol}) Price
                </Title>
                <p>
                    {cryptoDetails.name} live price in US dollars.
                    View value statistic,market cap and supply
                </p>
            </Col>
            <Select
                defaultValue="7"
                    className="select-timeperiod"
                    placeholder="Select time period(days)"
                    onChange={(value => setTimeperiod(value))}
            >
                {time.map((date)=><Option key={date}>{date}</Option>)}

            </Select>
            <LineChart greedHistory={data2} currentPrice={2} coinName={'bitcoin'} />
            <Col className="stats-container">
                    <Col className="coin-value-statistic">
                            <Col className="coin-value=statistic-heading">
                                <Title level={3} className="coin-details-heading">
                                    Statistics of {cryptoDetails.name}

                                </Title>
                                <p>An overview showing the stats of {cryptoDetails.name} </p>
                            </Col>
                        {stats.map(({icon,value,title,}) =>(
                                <Col className="coin-stats">
                                <Col className="coin-stats-name">
                                    <Text >{icon}</Text>
                                    <Text >{title}</Text>
                                </Col>
                                <Text className="stats">{value}</Text>
                            </Col>

                        ))}
                    </Col>
                <Col className="other-stats-info">
                    <Col className="coin-value=statistic-heading">
                        <Title level={3} className="coin-details-heading">
                            Other statistics
                        </Title>
                        <p>Other statistics</p>
                    </Col>
                        {genericStats.map(({icon,value,title}) =>(
                            <Col className="coin-stats">
                                <Col className="coin-stats-name">
                                    <Text key={3}>{icon}</Text>
                                    <Text key={4}>{title}</Text>
                                </Col>
                                <Text className="stats">{value}</Text>
                            </Col>
                        ))}
                </Col>
            </Col>
        </Col>
    )
}

export default CryptoDetails
