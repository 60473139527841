import React, {useState} from 'react'
import {useGetCryptoExchangesQuery} from "../services/cryptoExchangesApi";
import millify from 'millify';
import { Collapse, Row, Col, Typography, Avatar } from 'antd';
import HTMLReactParser from 'html-react-parser';
import Loader from "./Loader";


const { Text } = Typography;
const { Panel } = Collapse;

const Exchanges = () => {

    const{data:exchangesList} = useGetCryptoExchangesQuery();
    console.log(exchangesList)
    if(exchangesList == undefined) return   <Loader/>


    return (
        <>
            <Row>
                 {exchangesList.map((exchange) => (
          <Col span={24} >
            <Collapse bordered="true">
              <Panel
                key={exchange.id}
                showArrow={true}
                header={(
                  <Row key={exchange.country}>
                    <Col span={24}>
                      <Text><strong>{exchange.trust_score_rank}</strong></Text>
                      <Avatar className="exchange-image" src={exchange.image} />
                      <Text >{exchange.name}</Text>
                    </Col>
                  </Row>
                  )}
              >
                {HTMLReactParser(exchange.name + ' is an exchange from ' + exchange.country + '. Established at ' + exchange.year_established + '. Now their trust score is:' + exchange.trust_score )}

              </Panel>
            </Collapse>
          </Col>
        ))}
            </Row>
        </>
    )
}

export default Exchanges
