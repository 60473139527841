import React, {useState,useEffect} from 'react'
import millify from "millify";
import {Link} from 'react-router-dom';
import {Card, Row, Col, Input} from 'antd';
import {useGetCryptosQuery} from "../services/cryptoApi";
import Loader from "./Loader";

const Cryptocurrencies = ({simplified}) => {

    const count = simplified ? 12 :50
    const {data: cryptoList, isFetching} = useGetCryptosQuery(count);
    const [cryptos, setCryptos] =  useState('')
    const [searchTerm,setSearchTerm] = useState('')

    useEffect(()=>{
        if (!isFetching){
            setCryptos(cryptoList);
            const filteredData = cryptoList.filter((i)=>i.name.toLowerCase().includes(searchTerm))
            setCryptos(filteredData);
        }
    },[cryptoList,searchTerm])

    if (cryptos=='') {
        return  <Loader/>
    }
    console.log(cryptoList)
    console.log(searchTerm)

    console.log(cryptos)
    return (
        <>
            {!simplified &&(
                <div className="search-crypto">
                    <Input placeholder="Search crypto" onChange={(e) => setSearchTerm(e.target.value)}/>
                </div>
            )}


            <Row gutter={[32, 32]} className="crypto-card-container">
                {cryptos?.map((coin) => (
                    <Col xs={24} sm={12} lg={6} className="crypto-card" key={coin.id}>
                        <Link to={`/crypto/${coin.name.toLowerCase()}`}>
                            <Card
                                title={`${coin.cmcRank}. ${coin.name}`}
                                extra={<img className="crypto-image" src={coin.logo}/>}
                                hoverable
                                style={coin.quotes.map((item) =>millify(item.percentChange24h))<0?{ backgroundColor: '#ef5350' }:{ backgroundColor: '#27a69a'}}
                            >
                                <p>Price: {coin.quotes.map((item) =>millify(item.price))}$</p>
                                <p>Market Cap: {coin.quotes.map((item) =>millify(item.marketCap))}</p>
                                <p>Daily Change: {coin.quotes.map((item) =>millify(item.percentChange24h))}%</p>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Cryptocurrencies
