import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import cors from 'cors'


const cryptoApiHeaders = {
    'x-rapidapi-host': 'crypto-ranking-data.p.rapidapi.com',
    'x-rapidapi-key': '0444a31721msh4cb20a5be417bafp166782jsn12eb1e9c9aa9'

}

const baseUrl = 'https://crypto-ranking-data.p.rapidapi.com';

const createRequest = (url) => ({
    url,headers:cryptoApiHeaders
})



export const cryptoApi = createApi({
    reducerPath:'cryptoApi',

    baseQuery:fetchBaseQuery({baseUrl}),
    endpoints:(builder) =>({
        getTotalInfo:builder.query({
            query:(count) => createRequest(`/market/stats`)
        }),
        getCryptos:builder.query({
            query:(count) => createRequest(`/market/list/1/${count}`)
        }),
        getCryptoDetails:builder.query({
            query:(coinName) => createRequest(`/market/list/${coinName}`)
        }),
        getFearAndGreedIdx:builder.query({
            query:(range) => createRequest(`/fng/${range}`)
        }),
        getExchanges:builder.query({
            query:(count) => createRequest(`/exchanges`)
        }),
    })
})

export const {
    useGetTotalInfoQuery,
    useGetCryptosQuery,
    useGetCryptoDetailsQuery,
    useGetFearAndGreedIdxQuery,
        useGetExchangesQuery,
} = cryptoApi



// var options = {
//     method: 'GET',
//     ,
//     headers: {
//
//     }
//   };
