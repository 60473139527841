import React from 'react';
import { Line } from 'react-chartjs-2';
import { Col, Row, Typography } from 'antd';
import Chart from 'chart.js/auto'
const { Title } = Typography;

const LineChart = ({ greedHistory, currentPrice, coinName }) => {


    const greedValue = [];
    const greedTimestamp = [];

        greedHistory.map(i => {
            greedValue.push(i.value)
        })

       greedHistory.map(i =>{
           let ts = i.timestamp;
           let ts_ms = ts * 1000;
           let date_ob = new Date(ts_ms);
           let year = date_ob.getFullYear();
           let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
           let date = ("0" + date_ob.getDate()).slice(-2);
           let hours = ("0" + date_ob.getHours()).slice(-2);
           let minutes = ("0" + date_ob.getMinutes()).slice(-2);
           let seconds = ("0" + date_ob.getSeconds()).slice(-2);

           let newDate =
           greedTimestamp.push(year + "-" + month + "-" + date)
        })

    const reversed = greedTimestamp.reverse()

    const data = {
        labels: reversed,
        datasets: [
            {
                label: 'GREED/FEAR POINTS',
                data: greedValue,
                fill: false,
                backgroundColor: '#0071bd',
                borderColor: '#0071bd',
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (
        <>
            <Row className="chart-header">
                <Title level={2} className="chart-title">   Fear/Greed  Chart </Title>
                <Col className="price-container">
                    <p>0–24: Extreme fear</p>
                    <p> 25–49: Fear</p>
                    <p> 50–74: Greed</p>
                    <p> 75–100: Extreme greed</p>
                </Col>
            </Row>
            <Line data={data} options={options} />
        </>
    );
};

export default LineChart;
