import React,{useState,useEffect} from 'react'
import { Button,Menu,Typography,Avatar } from 'antd';
import  {Link} from 'react-router-dom';
import { HomeOutlined, MoneyCollectOutlined,BulbOutlined,FundOutlined,MenuOutlined } from '@ant-design/icons/lib/icons';
import icon from '../imgs/cryptocurrency.png'

const Navbar = () => {
    const [activeMenu,setActiveMenu] = useState(true)
    const [screenSize,setScreenSize] = useState(null)

    useEffect(()=>{
        const handleResize = () =>setScreenSize(window.innerWidth)
        window.addEventListener('resize',handleResize);
        handleResize();
        return ()=> window.removeEventListener('resize',handleResize)
    },[])

    useEffect(()=>{
        if(screenSize<768){
            setActiveMenu(false)
        } else {
            setActiveMenu(true)
        }
    },[screenSize])
    return (
        <div className ="nav-container">
            <div className="logo-container ">
                <Avatar src='https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/285/hamster_1f439.png' size="large" />
                <Typography.Title level={3} className='logo'>
                    <Link to="/">Market Cap </Link>
                </Typography.Title>
                <Button className="menu-control-container" onClick={()=>setActiveMenu(!activeMenu)}>
                    <MenuOutlined/>
                </Button>
            </div>
            {activeMenu &&(
            <Menu >
                <Menu.Item icon={<HomeOutlined/>}>
                    <Link to="/">Home</Link>
                </Menu.Item>

                <Menu.Item icon={<FundOutlined/>}>
                    <Link to="/cryptocurrencies">Cryptocurrencies</Link>
                </Menu.Item>

                <Menu.Item icon={<MoneyCollectOutlined/>}>
                    <Link to="/exchanges">Exchanges</Link>
                </Menu.Item>

                <Menu.Item icon={<BulbOutlined/>}>
                    <Link to="/news">News</Link>
                </Menu.Item>
            </Menu>
            )}
        </div>
    )
}

export default Navbar
