import React from 'react'
import moment from 'moment'
import {useGetCryptoNewsQuery} from "../services/cryptoNewsApi";
import {Card, Col, Row, Select, Typography} from "antd";
import millify from "millify";
import '../App.css'
import {Link} from "react-router-dom";
import Loader from "./Loader";
const {Text,Title} = Typography;
const {Option} = Select

const demoImage = 'https://cdn-icons-png.flaticon.com/512/21/21601.png'

const News = ({simplified}) => {

    const{data:cryptoNews} = useGetCryptoNewsQuery(10)
    console.log(cryptoNews)
    if(cryptoNews == undefined) return <Loader/>

    let simplifiedArr = cryptoNews.slice(1,10 )
    console.log(simplifiedArr)
    return (
        <Row gutter={[24,24]}>

            {simplified?simplifiedArr.map((news,i)=>(
                <Col xs={24} sm={12} lg={8} key={i}>
                    <Card hoverable className="news-card">
                            <a href={news.link} target="_blank" rel="noreferrer">
                                <div className="news-image-container">
                                    <h5 className="news-title">{news.title}</h5>
                                    <img src={demoImage} width="50px" height="50px"/>
                                </div>
                                <img src='https://media4.giphy.com/media/RgxAkfVQWwkjS/giphy.gif?cid=ecf05e47cwxstb5jseaq8c6k42oy103bulpz5v2npxb3k1zk&rid=giphy.gif&ct=g' className='gif'/>
                                <p>News From {news.source} posted {moment(news.pubDate).startOf('ss').fromNow()}</p>
                            </a>
                    </Card>
                </Col>

            )):cryptoNews.map((news,i)=>(
                <Col xs={24} sm={12} lg={8} key={i}>
                    <Card hoverable className="news-card">
                        <a href={news.link} target="_blank" rel="noreferrer">
                            <div className="news-image-container">
                                <h5 className="news-title">{news.title}</h5>
                                <img src={demoImage} width="50px" height="50px"/>
                            </div>
                            <img src='https://media4.giphy.com/media/RgxAkfVQWwkjS/giphy.gif?cid=ecf05e47cwxstb5jseaq8c6k42oy103bulpz5v2npxb3k1zk&rid=giphy.gif&ct=g' className='gif'/>
                            <p>News From {news.source} posted {moment(news.pubDate).startOf('ss').fromNow()}</p>
                        </a>
                    </Card>
                </Col>))}
        </Row>
    )
}

export default News
